export const Contact = () => {
    return (
        <div className="contact">
            <div className="container">
                <div className="row border">
                    <div className="col-md-8">
                        <div className="roserrep">
                            <h2>RO REPAIR</h2>
                            <p>RO purifier requires reliable organization after a specific length. This is crucial for keeping up the idea of water unadulterated and clean. Since the ground water is dirtied, it is essential to drink impeccable and unadulterated water to stay sound. We have an aggregate gathering of talented experts who give you appropriate help. Also, our specialists visit the site for various purposes like supplanting the RO channel et cetera. Give a call now remembering the true objective to engineer the visit of expert for the assurance of the significant number of issues in your RO.</p>

                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="textrepair">
                            <img src="img/wedo/about-1.jpg" className="img-fluid" alt="ro repair contact number" />
                        </div>
                    </div>
                </div>

                <div className="row border">

                    <div className="col-md-4">
                        <div className="textrepair">
                            <img src="img/wedo/about-2.jpg" className="img-fluid" alt="aquaguard service center in patna" />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="roserrep">
                            <h2>RO AMC PLANS</h2>
                            <p>Choose from our comprehensive RO AMC plans for hassle-free maintenance. Our packages include regular servicing, repairs, and replacement of parts to ensure your RO system operates at peak efficiency. With flexible options and expert Repair, we guarantee clean, safe water for your home or business.</p>

                        </div>
                    </div>

                </div>


                <div className="row border">
                    <div className="col-md-8">
                        <div className="roserrep">
                            <h2>RO INSTALLATION</h2>
                            <p>Our team of experienced technicians in Patna follows a meticulous installation process to ensure optimal performance of your RO system. We begin with a thorough inspection of your water source and plumbing layout to determine the best location for installing the RO unit. We then proceed with installing the system, including mounting the filters, connecting the pipes, and ensuring all components are securely fitted. Our goal is not just to install the RO system but to integrate it seamlessly into your existing water supply, providing you with immediate access to purified water.</p>

                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="textrepair">
                            <img src="img/wedo/about-3.jpg" className="img-fluid" alt="kent ro customer care number" />
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
} 