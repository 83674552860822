export const Bluebanner = () => {
    return (
        <div className="rorepair" id="bluebanner">
            <div className="container">
                <h1>RO Repair in Bangalore</h1>
                <p>
                    Maintaining clean and healthy drinking water is essential for every household and business in Patna. At RO Care Repair, we specialize in providing reliable RO Repair solutions to ensure your water purification systems operate at peak efficiency.
                </p>

            </div>
        </div>
    )
} 