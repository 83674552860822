export const Typopofro = () => {
    return (
        <div class="typero">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">

                        <div class="domesticro">
                            <img src="img/wedo/domesticro.jpg" alt="ro service center in bangalore" />
                            <h4><strong>Domestic Ro Repair</strong></h4>
                            <p>Ensure pure water at home with our Domestic RO Repair. We specialize in maintaining, repairing, and installing RO systems to safeguard your family's health. Our expert technicians deliver prompt, reliable Repair, using quality parts for optimal performance. Trust us to keep your water clean and your peace of mind intact.</p>

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="comercialro">
                            <img src="img/wedo/commercialro.jpg" class="img-fluid" alt="kent ro service center" />
                            <h4><strong>Commercial Ro Repair</strong></h4>
                            <p>Elevate your business with our Commercial RO Repair. From restaurants to offices, we offer comprehensive maintenance, repair, and installation solutions for RO systems. Our skilled technicians ensure uninterrupted water purity, using advanced techniques and premium parts. Depend on us for efficiency, reliability, and superior water quality, tailored to your commercial needs.</p>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}