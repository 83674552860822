export const Wedo = (props) => {
    return (
        <div className="allservice">
            <div className="container">
                <div className="row">

                    <div className="col-md-3">
                        <div className="servicepart">
                            <img src="img/wedo/roservice3.png" className="img-fluid" alt="aquaguard ro repair" />
                            <span> RO Repair @299</span>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="servicepart">
                            <img src="img/wedo/roservice2.png" className="img-fluid" alt="kent ro installation" />
                            <span> RO Repair/RO Installation</span>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="servicepart">
                            <img src="img/wedo/service.png" className="img-fluid" alt="aquaguard ro service" />
                            <span> RO Installation</span>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="servicepart">
                            <img src="img/wedo/roservice4.png" className="img-fluid" alt="kent ro service reapir" />
                            <span> Online Help 24x7</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
} 