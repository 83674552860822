import React from "react";

export const Process = () => {
    return (
        <div className="solution" id="process"> 
	<div className="container">
		<div className="row">
			
			<div className="col-md-7">
				<div className="filter">
					<div className="filter-img"><img src="img/call.jpeg" className="img-fluid" alt="ro repair water purifier" /></div>
					 
				</div>
			</div>

			<div className="col-md-5">
				<h3>Our <strong>RO Solution</strong> Process:</h3>
				<div className="sernum">
                    <div className="number">1</div>
					<div className="para">If you have problem in your RO like water taste is hassy or TDS problem call us for technician visit. </div>
				</div>

				<div className="sernum">
                    <div className="number">2</div>
					<div className="para">If any part of your RO is not working, then our technician puts a new part in it. and Repair Also. </div>
				</div>

				<div className="sernum">
                    <div className="number">3</div>
					<div className="para">If you want spare parts or AMC plan for 1year or 2years call us, We provide RO warrent include all parts in you RO. </div>
				</div>

				<div className="sernum">
                    <div className="number">4</div>
					<div className="para">If your RO filter is not working properly or not filtering water, change your RO filter for pure &amp; healthy water. Membrane should be changed every 2 years or if you notice a significant drop in water passing through the RO system.  </div>
				</div>

			 

				 
			</div>

		</div>
	</div>
</div>
    )
}