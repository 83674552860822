
export const Navigation = (props) => {
    return (
        <div id='menu' className=''>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="logo col-xs-8 col-md-8">
                        <a className='' href='/'>
                            <img style={{ height: 60, overflow: 'hidden' }} className="img-fluid" alt="RO Care Service Center |  ro system repair" title="ro repair service" src="img/rologo.png" />
                        </a>{' '}
                    </div>
                    <div className="callnow col-xs-4 col-md-4">
                        <i className="fa fa-phone"></i>
                        <span className="phone"><a href="tel:18005710709">18005710709</a></span>
                    </div>
                </div>
            </div>
        </div>
    )
}