export const Contactusbanner = () => {
    return (
        <div className="servicecenter">
	 <div className="container">
	 	<div className="row">
	 		<div className="col-md-12">

              <div className="servicelocaiton">
                <h2>Contact Us:</h2>
                <p>Whether you need routine maintenance or emergency repairs, RO Care Repair is your trusted partner for RO repair in Bangalore. Contact us today at 18005710709 or visit our website to schedule an appointment. Let us help you ensure your family or business enjoys clean and safe drinking water every day.</p>

					<p>Ensure the health and safety of your loved ones with RO Care Service in Bangalore. Trust us for reliable RO repair solutions tailored to your needs. Our Ro Installation Charge is Normal 299/- Call Any Time 18005710709</p>
                <h4>Email Us:</h4>
                <p>roservicesince2000@gmail.com<a href="mailto: aquapureroservices@gmail.com"></a></p>
              </div>
	 		</div>
	 	
	 	 
	 </div>
    </div>
</div>
    )
}