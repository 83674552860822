import React from "react";

export const Privacypolicy = (props) => {
    return (
        <div id='policy' >

            <div className="solution">
                <div className="container">
                    <a href="/">Back To Home</a>
                    <div className="row">

                        <div className="col-md-7">
                            <div className="filter">
                                <div className="filter-img"><img src="img/wedo/water.jpg" className="img-fluid" alt="ro repair water purifier" /></div>

                            </div>
                        </div>

                        <div className="col-md-5">
                            <h3>Privacy Policy</h3>
                            Thank you for choosing RO Care Service Center for your RO service needs. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [Your Website URL], including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
                            <br /><br />
                            <a href="/">Back To Home</a>
                        </div>

                    </div>

                </div>

            </div>
            <div className="rorepair">
                <div className="container">
                    1. Information We Collect

                    We may collect information about you in a variety of ways. The information we may collect on the Site includes:

                    Personal Data:
                    Name
                    Address
                    Email address
                    Phone number
                    Payment information (such as credit card details)
                    Any other information voluntarily provided by you

                    Non-Personal Data:
                    Browser type
                    Demographic information
                    IP address
                    Operating system used

                    <br /><br />
                    2. How We Use Your Information

                    We use the information we collect in the following ways:

                    To process and fulfill your orders for RO services
                    To improve our products and services
                    To send you promotional emails about new products, special offers, or other information which we think you may find interesting using the email address which you have provided
                    To contact you for market research purposes
                    To customize the website according to your interests
                    <br /><br />
                    3. Disclosure of Your Information

                    We may share your information with third parties only in the ways that are described in this privacy statement.

                    Third-Party Service Providers: We may share your information with third-party vendors, consultants, and other service providers who perform services on our behalf.
                    Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                    Compliance with Laws: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process.
                    <br /><br />
                    4. Security of Your Information

                    We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
                    <br /><br />
                    5. Changes to This Privacy Policy

                    We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                    <br /><br />
                        6. Contact Us

                        If you have any questions about this Privacy Policy, please contact us at:

                        RO Care Service Center
                        Phone: 18005710709
                    </div>
                </div>

            </div>
            )
}