import { useState, useEffect } from "react";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";

import { Herobanner } from "./components/herobanner";
import { Wedo } from "./components/wedo";
import { Process } from "./components/process";
import { Bluebanner } from "./components/bluebanner";
import { Imagegallery } from "./components/imagegallery";
import { Achive } from "./components/achive";
import { Contact } from "./components/contact";
import { Typopofro } from "./components/typeofro";
import { Contactusbanner } from "./components/contacusbnr";
import { Chooseus } from "./components/chooseus";
import { Callusbnr } from "./components/callbnr";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});



const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);


  return (
    <div>
      <Herobanner />
      <Wedo />
      <Process />
      <Bluebanner />
      <Imagegallery />
      <Achive />
      <Contact />
      <Typopofro />
      <Chooseus />
      <Contactusbanner />
      <Callusbnr />

    </div>
  );

}


export default Home;
